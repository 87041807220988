import React, { useState, useEffect } from "react";
import coupleImg1 from "../../images/couple/1.jpg";
import coupleImg2 from "../../images/couple/2.jpg";
import coupleImg1b from "../../images/couple/1b.jpg";
import coupleImg2b from "../../images/couple/2b.jpg";
import bg from "../../images/couple/image-bg.svg";
import { useTranslation } from "react-i18next";

const CoupleSection2 = (props) => {
  const [currentImage1, setCurrentImage1] = useState(coupleImg1);
  const [currentImage2, setCurrentImage2] = useState(coupleImg2);
  const [opacity1, setOpacity1] = useState(1);
  const [opacity2, setOpacity2] = useState(1);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const interval = setInterval(() => {
      // Fade out current image
      setOpacity1(0);
      setOpacity2(0);
      setTimeout(() => {
        // Switch images after fade out
        setCurrentImage1((prev) =>
          prev === coupleImg1 ? coupleImg1b : coupleImg1
        );
        setCurrentImage2((prev) =>
          prev === coupleImg2 ? coupleImg2b : coupleImg2
        );
        // Fade in new image
        setOpacity1(1);
        setOpacity2(1);
      }, 1000); // Matches transition duration
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // Inline styles for fade transitions
  const imageStyle1 = {
    transition: "opacity 1s ease-in-out",
    opacity: opacity1,
  };

  const imageStyle2 = {
    transition: "opacity 1s ease-in-out",
    opacity: opacity2,
  };

  return (
    <section className="wpo-couple-section section-padding" id="couple">
      <div className="container">
        <div className="couple-area clearfix">
          <div className="row align-items-center">
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <div className="couple-img-wrap">
                  <div className="couple-img">
                    <img style={imageStyle2} src={currentImage2} alt="" />
                  </div>
                  <div className="c-shape">
                    <img src={bg} alt="" />
                  </div>
                </div>
                <div className="couple-text">
                  <h3>{t("shokoName")}</h3>
                  <p>{t("shokoBio")}</p>
                </div>
              </div>
            </div>
            <div className="col col-md-12 col-12">
              <div className="couple-item">
                <div className="couple-img-wrap">
                  <div className="couple-img">
                    <img style={imageStyle1} src={currentImage1} alt="" />
                  </div>
                  <div className="c-shape">
                    <img src={bg} alt="" />
                  </div>
                </div>
                <div className="couple-text">
                  <h3>{t("jakeName")}</h3>
                  <p>{t("jakeBio")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoupleSection2;
