import React, { useEffect } from "react";
import { JackInTheBox } from "react-awesome-reveal";
import shape1 from "../../images/rsvp/shape.svg";
import shape2 from "../../images/rsvp/shape2.svg";
import shape3 from "../../images/rsvp/shape3.svg";
import shape4 from "../../images/rsvp/shape4.svg";
import shape5 from "../../images/rsvp/shape5.svg";
import shape6 from "../../images/rsvp/shape6.svg";
import RSVPFrom from "../RSVPFrom/RSVPFrom";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const RsvpSection = (props) => {
  const isMobile = window.innerWidth < 768;
  //Check what language is currently selected
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  useEffect(() => {
    //console log when language changes
    console.log("Language has changed to: ", currentLanguage);
  }, [currentLanguage]);

  return (
    <section
      className={`wpo-contact-section ${props.rClass} section-padding`}
      style={{ display: "flex", justifyContent: "center" }} // Center align the section content
    >
      <div>
        <div>
          <Row style={{ maxWidth: isMobile ? "90%" : "50%", margin: "0 auto" }}>
            {isMobile ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: 50, // Moved marginBottom here to avoid affecting other elements
                  }}
                >
                  {}
                  <button
                    type="submit"
                    className="theme-btn"
                    style={{
                      borderRadius: 8,
                      fontSize: 16,
                      width: 230,
                      marginBottom: 30,
                      visibility:
                        currentLanguage === "en" ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      window.open(
                        "https://app.collectionpot.com/pot/3232060",
                        "_blank"
                      );
                    }}
                  >
                    Wedding Gift 🎁 ご祝儀 (goshūgi)
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "70px",
                    fontSize: "1.2em",
                    lineHeight: "1.5em",
                    marginTop: "-20px",
                    visibility: currentLanguage === "en" ? "visible" : "hidden",
                  }}
                >
                  In Japanese culture, it is customary to give a monetary gift,
                  known as <b>ご祝儀 (goshūgi)</b>, to the bride and groom. If
                  you wish to participate, please use the provided link for
                  digital well-wishing envelopes. (This is entirely optional.)
                </div>
              </>
            ) : (
              <>
                <Col
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "200px", // Ensure the column has a height
                    marginLeft: -30,
                  }}
                >
                  <button
                    type="submit"
                    className="theme-btn"
                    style={{
                      borderRadius: 8,
                      fontSize: 16,
                      width: 230,
                      marginBottom: 50,
                      visibility:
                        currentLanguage === "en" ? "visible" : "hidden",
                    }}
                    onClick={() => {
                      window.open(
                        "https://app.collectionpot.com/pot/3232060",
                        "_blank"
                      );
                    }}
                  >
                    Wedding Gift 🎁 ご祝儀 (goshūgi)
                  </button>
                </Col>
                <Col>
                  <div
                    style={{
                      textAlign: "center",
                      marginBottom: "100px",
                      fontSize: "1.2em",
                      lineHeight: "1.5em",
                      marginTop: "-20px",
                      marginLeft: -30,
                      visibility:
                        currentLanguage === "en" ? "visible" : "hidden",
                    }}
                  >
                    In Japanese culture, it is customary to give a monetary
                    gift, known as <b>ご祝儀 (goshūgi)</b>, to the bride and
                    groom. If you wish to participate, please use the provided
                    link for digital well-wishing envelopes. (This is entirely
                    optional.)
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
        <div
          className="container-fluid"
          style={{
            width: isMobile ? "90%" : "100%",
            marginTop: 0,
            marginBottom: -200,
            display: "flex",
            justifyContent: "center", // Center align the form within the container
            marginTop: currentLanguage === "en" ? 0 : -200,
          }}
        >
          <iframe
            src={
              currentLanguage !== "en"
                ? "https://docs.google.com/forms/d/e/1FAIpQLSfoj3SqQjK___6xqkAVuzUMpomHoVGGZcuVwW8acnfDP08sgw/viewform?embedded=true"
                : "https://docs.google.com/forms/d/e/1FAIpQLScJg0WDprucXH1onYPe6jBBDmfcSbnfuggzAJl6y5KJ6jd84g/viewform?embedded=true"
            }
            title="RSVP Form"
            width="100%" // Ensure the iframe takes up the full width of its container
            height="2000"
            frameBorder="0" // Use camelCase for HTML attributes
            marginHeight="0"
            marginWidth="0"
          >
            Loading…
          </iframe>
        </div>
        <div className="bottom-bg">
          <svg
            viewBox="0 0 1920 634"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="bg-path"
              d="M207 286C162.2 87.6 39 44.6667 -17 48L-91 22L-71 726H13L1977 670L1957 424C1926.6 318.4 1815 392 1763 442C1619.8 570 1503.33 495.333 1463 442C1270.2 162.8 1197.33 325.667 1185 442C1159.4 584.4 1117 537.333 1099 496C953.4 192.8 868.333 328.333 844 434C791.2 649.2 649.333 555.667 585 482C455.4 356.4 380.333 429.667 359 482C315 616.4 273.333 547.333 258 496L207 286Z"
              fill=""
            />
            <path
              className="bg-stroke"
              d="M207 266C162.2 67.6 39 24.6667 -17 28L-91 2L-71 706H13L1977 650L1957 404C1926.6 298.4 1815 372 1763 422C1619.8 550 1503.33 475.333 1463 422C1270.2 142.8 1197.33 305.667 1185 422C1159.4 564.4 1117 517.333 1099 476C953.4 172.8 868.333 308.333 844 414C791.2 629.2 649.333 535.667 585 462C455.4 336.4 380.333 409.667 359 462C315 596.4 273.333 527.333 258 476L207 266Z"
              stroke=""
              strokeWidth="2"
            />
          </svg>
          <div className="shape-1">
            <img src={shape1} alt="" />
          </div>
          <div className="shape-2">
            <JackInTheBox direction="up" duration="2000" triggerOnce={true}>
              <img src={shape2} alt="" />
            </JackInTheBox>
          </div>
          <div className="shape-3">
            <JackInTheBox direction="up" duration="3000" triggerOnce={true}>
              <img src={shape3} alt="" />
            </JackInTheBox>
          </div>
          <div className="shape-4">
            <img src={shape4} alt="" />
          </div>
          <div className="shape-5">
            <JackInTheBox direction="up" duration="3500" triggerOnce={true}>
              <img src={shape5} alt="" />
            </JackInTheBox>
          </div>
          <div className="shape-6">
            <JackInTheBox direction="up" duration="4000" triggerOnce={true}>
              <img src={shape6} alt="" />
            </JackInTheBox>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RsvpSection;
