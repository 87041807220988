import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import "./style.css";
import { useTranslation } from "react-i18next";
import { Languages } from "lucide";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(251, 204, 147, 0.7);
  }
  70% {
    box-shadow: 0 0 0 25px rgba(251, 204, 147, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(251, 204, 147, 0);
  }
`;

const PulsingButton = styled.button`
  border-radius: 8px;
  font-size: 15px;
  animation: ${pulseAnimation} 2s infinite;
  animation-iteration-count: 5;
`;

const TopLeftButtonContainer = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
`;

const Scrollbar = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const [language, setLanguage] = useState("日本語 🇯🇵"); // Default language
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (currentLanguage === "en") {
      localStorage.setItem("language", "en");
    } else {
      localStorage.setItem("language", "ja");
    }
  }, [currentLanguage]);

  const changeLanguage = () => {
    if (currentLanguage === "en") {
      i18n.changeLanguage("ja");
      setCurrentLanguage("ja");
      setLanguage("English 🏴󠁧󠁢󠁳󠁣󠁴󠁿");
    } else {
      i18n.changeLanguage("en");
      setCurrentLanguage("en");
      setLanguage("日本語 🇯🇵");
    }
  };

  useEffect(() => {
    console.log("currentLanguage: ", currentLanguage);
    if (currentLanguage === "en") {
      setLanguage("日本語 🇯🇵");
      i18n.changeLanguage("en");
    } else {
      setLanguage("English 🏴󠁧󠁢󠁳󠁣󠁴󠁿");
      i18n.changeLanguage("ja");
    }
  }, [currentLanguage]);

  return (
    <div
      onClick={() => changeLanguage()}
      style={{
        position: "fixed",
        top: 20,
        left: 20,
        zIndex: 1000,
      }}
    >
      <PulsingButton
        type="submit"
        className="theme-btn"
        style={{
          borderRadius: 8,
          fontSize: 15,
        }}
        onClick={() => changeLanguage()}
      >
        {language}
      </PulsingButton>
    </div>
  );
};

export default Scrollbar;
