import React, { useEffect, useState } from "react";
import styled from "styled-components";
import EventSection from "../../components/EventSection/EventSection";
import sImg1 from "../../images/event/1.png";
import sImg2 from "../../images/event/2.png";
import sImg3 from "../../images/event/3.png";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  background-color: #d9e3c3;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

const InformationPage = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const [localLanguage, setLocalLanguage] = useState(currentLanguage); // Default language
  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log("currentLanguage: ", currentLanguage);
    if (currentLanguage === "en") {
      i18n.changeLanguage("en");
      setLocalLanguage("en");
    } else {
      i18n.changeLanguage("ja");
      setLocalLanguage("ja");
    }
  }, [currentLanguage]);

  return (
    <Container>
      <Scrollbar
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />

      <EventSection
        events={
          localLanguage === "en"
            ? [
                {
                  Simg: sImg1,
                  title: "Accomodation",
                  li1: "Will you be coming from afar? We checked out the available options and suggest you start with the following",
                  li2: `
            <hr>
            <div>
            <a href="https://maps.app.goo.gl/rWGstqCa7cyEszHV9" target="_blank" style="text-decoration: none; color: inherit;">

                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Oiso Prince Hotel</div>
                    <div>Time to venu - 11 min Taxi</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/WdHr3raArDfMcyGY6" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">House for Rent</div>
                    <div>Time to venu - 15 min Taxi</div>
                    <div>Time to venu - 46 min Train then Bus</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/zwMBy3BoFiW79kWJ7" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Grand Hotel Kanachu</div>
                    <div>Time to venu - 13 min Taxi</div>
                    <div>Time to venu - 30 min Train then Bus</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/MJ97rRsWKqedz56y5" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">THE HOURS</div>
                    <div>Time to venu - 19 min Taxi</div>
                    <div>Time to venu - 44 min Train then Bus</div>
                </div>
                </a>
                <hr>
            </div>
            `,
                  animation: "1200",
                },
                {
                  Simg: sImg2,
                  title: "Attractions",
                  li1: "If you have a little extra time don't forget to visit the area's many tourist attractions before returning home",
                  li2: `
            <hr>
            <div>
            <a href="https://maps.app.goo.gl/ANpd6W95DkveJstv6" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Ueno Park and Zoo:</div>
                    <div>Tokyo's blend of nature, culture, and wildlife in one enchanting destination.</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/w6uLmRWgrqJhDLu29" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Museum of Emerging Science:</div>
                    <div>A captivating exploration of cutting-edge technology and innovation in an interactive environment.</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/iQtfc4u992J7xWtM6" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Tokyo DisneySea:</div>
                    <div>World-famous theme parks in Chiba, accessible by train.</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/V2NS9dxEMfbcJ2XdA" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Meiji Shrine:</div>
                    <div>Historic Shinto shrine in Shibuya, featuring serene nature walks and traditional architecture.</div>
                </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/McnAWoRxd1HKnahH8" target="_blank" style="text-decoration: none; color: inherit;">
                <div style="margin-bottom: 20px;">
                    <div style="font-weight: bold;">Senso-ji Temple:</div>
                    <div>Tokyo's oldest temple in Asakusa, with a bustling shopping street and cultural experiences.</div>
                </div>
                </a>
                <hr>
            </div>
            `,
                  animation: "1400",
                },
                {
                  Simg: sImg3,
                  title: "Local restraunts",
                  li1: "Want to try local food? Checl out the available options near the venue",
                  li2: `
            <hr>
            <div>
                <a href="https://maps.app.goo.gl/pBJsoYKEKX2xckVq8" target="_blank" style="text-decoration: none; color: inherit;">
                    <div style="margin-bottom: 20px; cursor: pointer;" >
                        <div style="font-weight: bold;">OISO CONNECT CAFE</div>
                    <div>9am - 6pm</div>
                </a>
                <hr>
                </div>
                <a href="https://maps.app.goo.gl/Bq6soK3n3WWJg1i39" target="_blank" style="text-decoration: none; color: inherit;">
                    <div style="margin-bottom: 20px; cursor: pointer">
                        <div style="font-weight: bold;">しゃぶ葉 国府津店<br>(Shabu-shabu restaurant)</div>
                        <div>11am - 11:30pm</div>
                    </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/FmPGhMuwLcP6u4zW9" target="_blank" style="text-decoration: none; color: inherit;">
                    <div style="margin-bottom: 20px; cursor: pointer">
                        <div style="font-weight: bold;">Uotami</div>
                        <div>5pm - 11pm</div>
                    </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/ty9KarhMerwnJdxDA" target="_blank" style="text-decoration: none; color: inherit;">
                    <div style="margin-bottom: 20px; cursor: pointer">
                        <div style="font-weight: bold;">Art Gallery 絵付工房 陶彩</div>
                        <div>10am - 6pm</div>
                    </div>
                </a>
                <hr>
                <a href="https://maps.app.goo.gl/cN6mpqxjDkzdaWQ68" target="_blank" style="text-decoration: none; color: inherit;">
                    <div style="margin-bottom: 20px; cursor: pointer">
                        <div style="font-weight: bold;">Gasto - ガスト 国府津店<br>(Easy and fast place to eat)</div>
                        <div>7am - 11pm</div>
                    </div>
                </a>
                <hr>
            </div>
            `,
                  animation: "1600",
                },
              ]
            : [
                {
                  Simg: sImg1,
                  title: t("accomodation"),
                  li1: t("accomodationText"),
                  li2: `
              <hr>
              <div>
              <a href="https://maps.app.goo.gl/rWGstqCa7cyEszHV9" target="_blank" style="text-decoration: none; color: inherit;">
  
                  <div style="margin-bottom: 20px;">
                      <div style="font-weight: bold;">${t("OsioHotel")}</div>
                      <div>${t("OsioTimeToCome")}</div>
                  </div>
                  </a>
                  <hr>
                  <a href="https://maps.app.goo.gl/WdHr3raArDfMcyGY6" target="_blank" style="text-decoration: none; color: inherit;">
                  <div style="margin-bottom: 20px;">
                      <div style="font-weight: bold;">${t("HouseRent")}</div>
                      <div>${t("HouseRentTimeToCome")}</div>
                      <div>${t("HouseRentTimeToVenue")}</div>
                  </div>
                  </a>
                  <hr>
                  <a href="https://maps.app.goo.gl/zwMBy3BoFiW79kWJ7" target="_blank" style="text-decoration: none; color: inherit;">
                  <div style="margin-bottom: 20px;">
                      <div style="font-weight: bold;">${t("GrandHotel")}</div>
                      <div>${t("GrandHotelTimeToCome")}</div>
                      <div>${t("GrandHotelTimeToVenue")}</div>
                  </div>
                  </a>
                  <hr>
                  <a href="https://maps.app.goo.gl/MJ97rRsWKqedz56y5" target="_blank" style="text-decoration: none; color: inherit;">
                  <div style="margin-bottom: 20px;">
                      <div style="font-weight: bold;">${t("TheHours")}</div>
                      <div>${t("TheHoursTimeToCome")}</div>
                      <div>${t("TheHoursTimeToVenue")}</div>
                  </div>
                  </a>
                  <hr>
              </div>
              `,
                  animation: "1200",
                },
              ]
        }
      />
      {/* <li>
<LocationMap />
</li> */}
    </Container>
  );
};

export default InformationPage;
