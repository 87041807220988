import React, { Fragment, useEffect } from "react";
import Hero2 from "../../components/hero2/hero2";
import CoupleSection2 from "../../components/CoupleSection2/CoupleSection2";
import StorySection2 from "../../components/StorySection2/StorySection2";
import PortfolioSection from "../../components/PortfolioSection";
import RsvpSection from "../../components/RsvpSection/RsvpSection";
import EventSection from "../../components/EventSection/EventSection";
import Scrollbar from "../../components/scrollbar/scrollbar";

const HomePage = (props) => {
  const { currentLanguage, setCurrentLanguage } = props;
  return (
    <Fragment>
      <Hero2
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
      <CoupleSection2
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
      <StorySection2
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
      {/* <PortfolioSection /> */}
      <div style={{ marginTop: 50 }}></div>
      <div id="rsvp-section"></div>
      <RsvpSection
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
      <Scrollbar
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
      />
    </Fragment>
  );
};

export default HomePage;
