import React, { Fragment } from "react";

import { Button, Dialog, Grid } from "@mui/material";
import MuiDialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

const LocationMap = (props) => {
  const { location } = props;
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const DialogTitle = (props) => {
    const { onClose } = props;
    return (
      <MuiDialogTitle>
        {onClose ? (
          <IconButton
            className="event-close-btn"
            aria-label="close"
            onClick={onClose}
          >
            <i className="ti-close"></i>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  console.log(location);

  return (
    <Fragment>
      <Button onClick={handleClickOpen}>See Location</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className="modalWrapper quickview-dialog"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {" "}
        </DialogTitle>
        <Grid className="modalBody modal-body">
          <Grid className="modalBody modal-body">
            <iframe src={location}></iframe>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};
export default LocationMap;
