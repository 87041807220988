import React, { useEffect, useState } from "react";
import { Zoom } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/story/1.jpg";
import sImg2 from "../../images/story/2.jpg";
import sImg3 from "../../images/story/3.jpg";

import shape1 from "../../images/story/shape.png";
import shape2 from "../../images/story/shape2.png";
import shape3 from "../../images/story/shape3.png";

import bshape1 from "../../images/story/flower-shape1.svg";
import bshape2 from "../../images/story/flower-shape2.svg";
import bshape3 from "../../images/story/flower-shape3.svg";
import bshape4 from "../../images/story/flower-shape4.svg";
import LocationMap from "../EventSection/Modal";
import { useTranslation } from "react-i18next";

const StorySection2 = (props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  let Storys =
    currentLanguage === "en"
      ? [
          {
            sImg: sImg1,
            shape: shape1,
            title: t("theLocation"),
            date: " ",
            description: t("theLocationText"),
            locationLink: "",
          },
          {
            sImg: sImg2,
            shape: shape2,
            title: t("theVenue"),
            date: " ",
            description: t("theVenueText"),
            order1: "order-lg-2 order-1",
            order2: "order-lg-1 order-2",
            locationLink:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d814.155427801273!2d139.2406583!3d35.2905305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019afefd8398d43%3A0x62e57cfdef4ace69!2sAT%20TERRACE%20ninomiya!5e0!3m2!1sen!2sjp!4v1715848415593!5m2!1sen!2sjp",
          },
          {
            sImg: sImg3,
            shape: shape3,
            title: t("supportTravel"),
            date: " ",
            description: t("supporTravelText"),
            locationLink: "",
          },
        ]
      : [
          {
            sImg: sImg2,
            shape: shape2,
            title: t("theVenue"),
            date: " ",
            description: t("theVenueText"),
            order1: "order-lg-2 order-1",
            order2: "order-lg-1 order-2",
            locationLink:
              "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d814.155427801273!2d139.2406583!3d35.2905305!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019afefd8398d43%3A0x62e57cfdef4ace69!2sAT%20TERRACE%20ninomiya!5e0!3m2!1sen!2sjp!4v1715848415593!5m2!1sen!2sjp",
          },
          {
            sImg: sImg3,
            shape: shape3,
            title: t("supportTravel"),
            date: " ",
            description: t("supporTravelText"),
            locationLink: "",
          },
        ];
  return (
    <section className="wpo-story-section section-padding" id="story">
      <div className="container">
        <SectionTitle subTitle={t("ourStory")} MainTitle={t("howItHappened")} />
        <div
          style={{
            textAlign: "center",
            marginBottom: "100px",
            fontSize: "1.2em",
            lineHeight: "1.5em",
            marginTop: "-20px",
          }}
        >
          {t("hotItHappenedText")}
        </div>
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="wpo-story-img-wrap">
                <Zoom direction="up" duration="1000" triggerOnce="true">
                  <div className="wpo-story-img">
                    <img src={story.sImg} alt="" />
                  </div>
                </Zoom>
                <div className="clip-shape">
                  <svg
                    viewBox="0 0 382 440"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                  </svg>
                </div>
                <div className="wpo-img-shape">
                  <img src={story.shape} alt="" />
                </div>
              </div>
              <div className="wpo-story-content">
                <div className="wpo-story-content-inner">
                  <span>{story.date}</span>
                  <h2>{story.title}</h2>
                  <p>
                    {" "}
                    <div
                      dangerouslySetInnerHTML={{ __html: story.description }}
                    />
                  </p>
                  {story.locationLink ? (
                    <LocationMap location={story.locationLink} />
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={bshape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={bshape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={bshape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={bshape4} alt="" />
      </div>
    </section>
  );
};

export default StorySection2;
