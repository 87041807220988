import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        title: "Jake & Shoko",
        subtitle: "We Are Getting Married May 4th 2025",
        headingButton: "CLICK HERE TO CONFIRM ATTENDANCE",
        shokoName: "Shoko Kitamura",
        shokoBio:
          "皆様 いかがお過ごしでしょうか。この度、北村祥子とジェイクレデンは記載の日程にて結婚式を執り行わせていただくことにいたしました。 結婚式といっても、皆に楽しんでもらえるお楽しみ会にしたいと思ってるので、ぜひ遊びに来てくれると嬉しいです！ よろしくお願いいたします。",
        jakeName: "Jake Reden",
        jakeBio:
          "Can you believe how quickly life flies by? It feels like just yesterday I was running wild in the countryside with my friends and cousins, going on all kinds of crazy adventures. And all of a sudden, here I am, living my life in the countryside of Japan with Shoko. I feel so lucky I found someone who's as wild and free as I am and I cant wait to have more adventures with her. As such, I look forward to celebrating that fact with you all on May 4th, 2025. With all the people who made up the memories in my life, it would mean the world to have you there celebrating with us.",
        ourStory: "Our Story",
        howItHappened: "How It Happened",
        hotItHappenedText:
          "Jake and Shoko met in 2018 as winter was just beginning. Bonding over shared music tastes, indulging in delicious food, and perhaps one too many beers, they quickly grew closer. Life has presented its fair share of challenges, but they've faced them together, strengthening their relationship along the way. Grateful for all they have overcome, they decided to celebrate the presence of each other in their lives by embarking on this journey of marriage.",
        theLocation: "The Location",
        theLocationText:
          "The wedding will be held in the Tokyo area, conveniently accessible from any major station in the city.",
        theVenue: "The Venue",
        theVenueText:
          "The venue is located by the seaside in the charming town of Ninomiya, just a short walk from Ninomiya train station.",
        supportTravel: "Support and Travel",
        supporTravelText:
          "If you follow <a style={{colo:'#1976d2'}} href='/information'>this link</a> you can see a list of recommendations for hotels and suggestions for restaurants as well as cool tourist spots, however we are happy to assist you with planning further if needed, just reach out to us at this email - <a style={{colo:'#1976d2'}} href='mailto:contact@reddenkitamura.com'>contact@reddenkitamura.com</a> .<br></br> For guests arriving from abroad, we suggest flying to Tokyo Haneda Airport, conveniently located in the city center. However, Narita Airport, though slightly farther, sometimes offers cheaper flights, albeit with the exchange of taking a rapid train to Tokyo's center for about an hour upon arrival.",
        accomodation: "Accomodation",
        accomodationText:
          "Will you be coming from afar? We checked out the available options and suggest you start with the following",
        stayOptions: "Stay options & recommendations",
        satyOptionsText: "Click any of the below to open in google maps",
        OsioHotel: "Oiso Prince Hotel",
        OsioTimeToCome: "Time to come - 11 min Taxi",
        HouseRent: "House for Rent",
        HouseRentTimeToCome: "Time to come - 15 min Taxi",
        HouseRentTimeToVenue: "Time to venue - 45 min Train then Bus",
        GrandHotel: "Grand Hotel Kanachu",
        GrandHotelTimeToCome: "Time to come - 13 min Taxi",
        GrandHotelTimeToVenue: "Time to venu - 30 min Train then Bus",
        TheHours: "THE HOURS",
        TheHoursTimeToCome: "Time to come - 19 min Taxi",
        TheHoursTimeToVenue: "Time to venue - 44 min Train then Bus",
      },
    },
    ja: {
      translation: {
        title: "Jake & Shoko",
        subtitle: "We Are Getting Married May 4th 2025",
        headingButton: "🪐 出席のご回答はこちら ☄️",
        shokoName: "北村 祥子",
        shokoBio:
          "皆様 いかがお過ごしでしょうか。この度、北村祥子とジェイクレデンは記載の日程にて結婚式を執り行わせていただくことにいたしました。 結婚式といっても、皆に楽しんでもらえるお楽しみ会にしたいと思ってるので、ぜひ遊びに来てくれると嬉しいです！ よろしくお願いいたします。",
        jakeName: "ジェイク レデン",
        jakeBio:
          "人生は振り返ると信じられないほどのスピードで、まるで昨日のことのように、友達や従兄弟と田舎で走り回り、色んな冒険をしていたことを思い出します。そして突然、今は祥子と共に日本の田舎で暮らしています。自由な精神を持つ彼女に出会えたことを幸運に感じています。これからも彼女と一緒に冒険を続けるのが楽しみです。そのため、2025年5月4日に皆さんと一緒にその喜びを祝いたいと思います。私の人生の思い出を作ってくれた皆さんと一緒に祝うことができれば幸いです。",
        ourStory: "Our Story",
        howItHappened: "How It Happened",
        hotItHappenedText:
          "ジェイクと祥子は2018年の冬の始まりに出会いました。共通の音楽の趣味、美味しい食べ物、そして大量のビールで絆を深めていきました。様々な困難もありましたが、共に乗り越え、関係を深めました。助け合いすべての困難を乗り越えてこられたことに感謝し、結婚という新たな旅に出ることを決意しました。",
        theLocation: "The Location",
        theLocationText:
          "結婚式は東京エリアで行われます。主要な駅からのアクセスが便利です。",
        theVenue: "The Venue",
        theVenueText:
          "AT TERRACE ninomiya <p></p>〒259-0124 神奈川県中郡二宮町山西３８２ <p></p>会場は二宮の海辺の魅力的な町にあり、当日は二宮駅から送迎バスをご利用いただけます。",
        supportTravel: "Support and Travel",
        supporTravelText:
          "遠方からお越しですか？<a style={{color:'#1976d2'}} href='/information'>リンク先</a>より周辺の宿泊施設をご確認いただけます。",
        accomodation: "Accomodation",
        accomodationText: "宿泊オプション",
        stayOptions: "滞在オプションとお勧め",
        satyOptionsText: "以下のいずれかをクリックしてGoogleマップで開きます",
        OsioHotel: "大磯プリンスホテル",
        OsioTimeToCome: "会場までの時間 - タクシーで11分",
        HouseRent: "ゲストハウス",
        HouseRentTimeToCome: "会場までの時間 - タクシーで15分",
        HouseRentTimeToVenue: "会場までの時間 - 電車とバスで46分",
        GrandHotel: "グランドホテル神奈中",
        GrandHotelTimeToCome: "会場までの時間 - タクシーで13分",
        GrandHotelTimeToVenue: "会場までの時間 - 電車とバスで30分",
        TheHours: "THE HOURS",
        TheHoursTimeToCome: "会場までの時間 - タクシーで19分",
        TheHoursTimeToVenue: "会場までの時間 - 電車とバスで44分",
      },
    },
  },
  lng: "en", // Default language
  fallbackLng: "en",

  interpolation: {
    escapeValue: false, // React already safes from xss
  },
});

export default i18n;
