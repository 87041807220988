import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import { JackInTheBox } from "react-awesome-reveal";
import shape1 from "../../images/rsvp/shape.svg";
import shape2 from "../../images/rsvp/shape2.svg";
import shape3 from "../../images/rsvp/shape3.svg";
import shape4 from "../../images/rsvp/shape4.svg";
import shape5 from "../../images/rsvp/shape5.svg";
import shape6 from "../../images/rsvp/shape6.svg";
import LocationMap from "./Modal";
import { ArrowLeft } from "lucide";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const EventSection = (props) => {
  const Events = props.events;
  const isSingleEvent = Events.length === 1;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate

  return (
    <section className="wpo-event-section section-padding" id="event">
      <div className="container" style={{ marginTop: 30 }}>
        <a
          onClick={() => navigate("/")} // Navigate to the previous page
          style={{
            position: "absolute",
            top: 50,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50px", // Adjust the height as needed
          }}
        >
          <button
            onClick={() => navigate(-1)} // Navigate to the previous page
            type="submit"
            className="theme-btn"
            style={{
              borderRadius: 8,
              fontSize: 12,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Back
          </button>
        </a>
        <SectionTitle subTitle={""} MainTitle={t("stayOptions")} />
        <div style={{ marginTop: -50 }}>
          <SectionTitle subTitle={t("satyOptionsText")} MainTitle={""} />
        </div>
        <div
          className="wpo-event-wrap"
          style={{ zIndex: 20, marginBottom: 200 }}
        >
          <div className="row">
            {Events.map((event, eitem) => (
              <div
                className={`col ${
                  isSingleEvent
                    ? "col-lg-12 col-md-12 col-12"
                    : "col-lg-4 col-md-6 col-12"
                }`}
                key={eitem}
                style={{
                  zIndex: 20,
                  textAlign: isSingleEvent ? "center" : "left",
                }}
              >
                <Slide
                  direction="up"
                  duration={event.animation}
                  triggerOnce="true"
                >
                  <div className="wpo-event-item">
                    <div
                      className="wpo-event-img"
                      style={{ background: "none" }}
                    >
                      <div
                        className="wpo-event-img-inner"
                        style={{ border: "10px solid white" }}
                      >
                        <img src={event.Simg} alt="" />
                      </div>
                    </div>
                    <div className="wpo-event-text" style={{ borderRadius: 8 }}>
                      <div className="title">
                        <h2 style={{ borderRadius: 8 }}>{event.title}</h2>
                      </div>
                      <ul>
                        <li>{event.li1}</li>
                        <li>
                          <div
                            dangerouslySetInnerHTML={{ __html: event.li2 }}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 0,
          }}
        >
          <svg
            viewBox="0 0 1920 634"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M207 286C162.2 87.6 39 44.6667 -17 48L-91 22L-71 726H13L1977 670L1957 424C1926.6 318.4 1815 392 1763 442C1619.8 570 1503.33 495.333 1463 442C1270.2 162.8 1197.33 325.667 1185 442C1159.4 584.4 1117 537.333 1099 496C953.4 192.8 868.333 328.333 844 434C791.2 649.2 649.333 555.667 585 482C455.4 356.4 380.333 429.667 359 482C315 616.4 273.333 547.333 258 496L207 286Z"
              fill="#A5AA9C"
            />
            <path
              d="M207 266C162.2 67.6 39 24.6667 -17 28L-91 2L-71 706H13L1977 650L1957 404C1926.6 298.4 1815 372 1763 422C1619.8 550 1503.33 475.333 1463 422C1270.2 142.8 1197.33 305.667 1185 422C1159.4 564.4 1117 517.333 1099 476C953.4 172.8 868.333 308.333 844 414C791.2 629.2 649.333 535.667 585 462C455.4 336.4 380.333 409.667 359 462C315 596.4 273.333 527.333 258 476L207 266Z"
              stroke="#A5AA9C"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div style={{ position: "absolute", bottom: 0, left: "2%" }}>
          <img src={shape1} alt="" />
        </div>
        <div style={{ position: "absolute", bottom: 0, left: "5%" }}>
          <JackInTheBox direction="up" duration="2000" triggerOnce="true">
            <img src={shape2} alt="" />
          </JackInTheBox>
        </div>
        <div style={{ position: "absolute", bottom: 0, left: "35%" }}>
          <JackInTheBox direction="up" duration="3000" triggerOnce="true">
            <img src={shape3} alt="" />
          </JackInTheBox>
        </div>
        <div style={{ position: "absolute", bottom: 0, left: "65%" }}>
          <img src={shape4} alt="" />
        </div>
        <div style={{ position: "absolute", bottom: 0, left: "80%" }}>
          <JackInTheBox direction="up" duration="3500" triggerOnce="true">
            <img src={shape5} alt="" />
          </JackInTheBox>
        </div>
        <div style={{ position: "absolute", bottom: 0, left: "95%" }}>
          <JackInTheBox direction="up" duration="4000" triggerOnce="true">
            <img src={shape6} alt="" />
          </JackInTheBox>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
